<script>
import Vue from 'vue';
import GlobalVue from '@helper/Global.vue';

Vue.component("Header",()=>import("@frontend/part/Header.vue"))
Vue.component("Footer",()=>import("@frontend/part/Footer.vue"))

Vue.component("VModal",()=>import("@frontend/components/VModal.vue"))

export default {
  extends: GlobalVue,
  name:"FoLayout",
  mounted(){
    this.$set(this.$root, 'base', this)
  }
};
</script>

<template>
  <div id="wrapper" class="clearfix">
    <section id="">
      <router-view/>
    </section>
    <Footer></Footer>
    <div id="gotoTop" class="icon-line-arrow-up"></div>
    <ImageFilter></ImageFilter>
    <!-- <VAlert></VAlert> -->
    <VModal></VModal>
  </div>
</template>